import React from 'react';
import { Provider } from 'react-redux';
import App from '@/components/App';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from '@/reducers';

const middleware = [thunk];
const store = createStore(rootReducer, applyMiddleware(...middleware));

const WrapWithProvider = ({ element }) => {
  return (
    <Provider store={store}>
      <App>{element}</App>
    </Provider>
  );
};

export default WrapWithProvider;
