import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './src/css/global.css';
import { createRoot } from 'react-dom/client';
import type { GatsbyBrowser } from 'gatsby';
import wrapWithProvider from '@/wrap-with-provider';

export const onServiceWorkerUpdateReady = () => {
  if (typeof window !== 'undefined') {
    const answer = window.confirm(
      `This application has been updated. ` + `Reload to display the latest version?`
    );

    if (answer === true) {
      window.location.reload();
    }
  }
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container);
    root.render(element);
  };
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = wrapWithProvider;
